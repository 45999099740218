import { Alert, Autocomplete, Box, Paper, TextField } from "@mui/material";
import { debounce } from "@mui/material/utils";
import { escapeRegExp, fromPairs, uniqBy } from "lodash-es";
import * as React from "react";
import { useHistory } from "react-router";
import { getHierarchy, Item } from "#components/Tree/index.tsx";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useHideFooter from "#helpers/hooks/useHideFooter.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { Tree } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import { useDatasetPrefixes } from "../../../helpers/hooks/useDatasetPrefixes";
import { stringifyQuery } from "../../../helpers/utils";
import { useCurrentDataset } from "../../../reducers/datasetManagement";
import * as styles from "./style.scss";

const SubclassTree: React.FC<{}> = ({}) => {
  const history = useHistory();
  const currentResource = useCurrentResource();
  const prefixes = useDatasetPrefixes();
  useHideFooter();

  const conceptLimit = 1000;

  const { data, error } = useSparql<Item[]>(`
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
prefix owl: <http://www.w3.org/2002/07/owl#>
select ?id ?parent (sample(?l) as ?label) (sample(?_conceptScheme) as ?conceptScheme) (sample(?_conceptSchemeLabel) as ?conceptSchemeLabel) where {
  {
    {
      select distinct ?concept where {
        {
          ?concept a/rdfs:subClassOf* skos:Concept
        }  union {
          ?concept skos:broader|^skos:broader|skos:narrower|^skos:narrower|skos:broadMatch|^skos:broadMatch|skos:narrowMatch|^skos:narrowMatch []
        }
        filter (!contains(str(?concept), ".well-known/genid"))
      }
      limit ${conceptLimit}
    }
    optional {
      ?concept skos:broader|^skos:narrower|skos:broadMatch|^skos:narrowMatch ?superclass
    }
    optional {
      ?concept rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?l
    }
    optional {
      ?concept skos:inScheme ?_conceptScheme
      optional {
        ?_conceptScheme rdfs:label|skos:prefLabel ?_conceptSchemeLabel
      }
    }
    bind(coalesce(?superclass, <tree:root>) as ?parent)
    bind(?concept as ?id)
  }
}
group by ?id ?parent
      `);

  const [expandedKeys, setExpandedKeys] = React.useState<{ [key: string]: boolean }>({});

  React.useEffect(() => {
    if (currentResource && data) {
      const { hierarchy } = getHierarchy(data, currentResource, prefixes);
      const toExpand = fromPairs(
        hierarchy
          ?.descendants()
          .filter((node) => node.data.iri === currentResource)
          .flatMap(
            (node) =>
              node
                .ancestors()
                .slice(1)
                .map((node) => [node.data.key, true]) || [],
          ),
      );
      setExpandedKeys((expandedKeys) => {
        return {
          ...expandedKeys,
          ...toExpand,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResource, data]);

  if (!data || error) return null;

  return (
    <Paper className="p-3">
      <Box className={styles.hierarchyContainer}>
        <div className="mb-5 flex center g-5">
          {data?.length === conceptLimit ? <SearchField /> : <SelectField data={data} />}
        </div>
        {data?.length === 0 && <Alert severity="info">No SKOS hierarchy available.</Alert>}
        <Tree
          className={styles.tree}
          items={data}
          currentItemIri={currentResource}
          expandedKeys={expandedKeys}
          onToggle={(e) => setExpandedKeys(e.value)}
          emptyMessage=" "
          onSelect={({ node }) => {
            history.push({ search: stringifyQuery({ resource: node.data.iri }) });
          }}
        />
      </Box>
    </Paper>
  );
};

const SelectField: React.FC<{ data: Item[] | undefined }> = ({ data }) => {
  const history = useHistory();
  const applyPrefixes = useApplyPrefixes();

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label || applyPrefixes(option.id))}
      getOptionKey={(option) => option.id}
      options={uniqBy(data || [], "id")}
      autoComplete
      disabled={(data || []).length === 0}
      filterSelectedOptions
      fullWidth
      noOptionsText="No results"
      onChange={(_event: any, newValue: Item | null, reason) => {
        if (reason === "selectOption") {
          history.push({
            search: stringifyQuery({ resource: newValue?.id }),
          });
        }
      }}
      renderInput={(params: any) => {
        return <TextField variant="outlined" placeholder="Filter" {...params} />;
      }}
    />
  );
};

interface Concept {
  id: string;
  label: string;
}
const SearchField: React.FC<{}> = () => {
  const [value, setValue] = React.useState<Concept | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly Concept[]>([]);

  const currentDs = useCurrentDataset()!;
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const sparql = React.useCallback(
    async (query: string, abortSignal: AbortSignal) => {
      const response = await fetch(sparqlUrl, {
        credentials: "same-origin",
        signal: abortSignal,
        method: "POST",
        headers: { Accept: "application/json" },
        body: new URLSearchParams({
          account: currentDs.owner.accountName,
          dataset: currentDs.name,
          queryString: query,
        }),
      });
      if (!response.ok) throw new Error(response.statusText);
      const result = await response.json();
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparqlUrl, currentDs.owner.accountName, currentDs.name],
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly Concept[]) => void,
        ) => {
          searchTerm = escapeRegExp(searchTerm.replace(/"/g, "")).replace(/\\/g, "\\\\");
          sparql(
            `
            prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
            prefix skos: <http://www.w3.org/2004/02/skos/core#>
            prefix skosxl: <http://www.w3.org/2008/05/skos-xl#>
            prefix owl: <http://www.w3.org/2002/07/owl#>

            select ?id ?label where {
              ?id a/rdfs:subClassOf* skos:Concept .

              filter (!regex(str(?id), "\.well-known/genid"))

              ?id rdfs:label|skos:prefLabel|skosxl:prefLabel/skosxl:literalForm ?label .

              filter (regex(?label, "${searchTerm}", "i"))

            }
            order by
              desc(regex(?label, "^${searchTerm}", "i"))
              asc(strlen(?label))
            limit 20
            `,
            abortSignal,
          )
            .then((results) => {
              callback(results);
            })
            .catch(() => {});
        },
        400,
      ),
    [sparql],
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debouncedQuery({ searchTerm: inputValue, abortSignal: abortController.signal }, (results?: readonly Concept[]) => {
      if (active) {
        let newOptions: readonly Concept[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [value, inputValue, debouncedQuery]);

  const history = useHistory();

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      fullWidth
      noOptionsText="No results"
      onChange={(_event: any, newValue: Concept | null, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (reason === "selectOption") {
          history.push({
            search: stringifyQuery({ resource: newValue?.id }),
          });
        }
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params: any) => {
        return <TextField variant="outlined" placeholder="Search" {...params} />;
      }}
    />
  );
};

export default SubclassTree;
