import { produce } from "immer";
import { fromPairs, groupBy } from "lodash-es";
import { Converter } from "sparqljson-to-tree";
import { Models, Routes } from "@triply/utils";
import { Dataset } from "#reducers/datasetManagement.ts";
import { Action, Actions, BeforeDispatch, GlobalAction } from "#reducers/index.ts";

const converter = new Converter({ materializeRdfJsTerms: true });

export const LocalActions = {
  GET_EDITOR_DESCRIPTION: "triply/resourceEditorDescriptions/GET_DESCRIPTION",
  GET_EDITOR_DESCRIPTION_SUCCESS: "triply/resourceEditorDescriptions/GET_DESCRIPTION_SUCCESS",
  GET_EDITOR_DESCRIPTION_FAIL: "triply/resourceEditorDescriptions/GET_DESCRIPTION_FAIL",
  GET_EDITOR_DESCRIPTION_CLASS: "triply/resourceEditorDescriptions/GET_CLASS_DESCRIPTION",
  GET_EDITOR_DESCRIPTION_CLASS_SUCCESS: "triply/resourceEditorDescriptions/GET_CLASS_DESCRIPTION_SUCCESS",
  GET_EDITOR_DESCRIPTION_CLASS_FAIL: "triply/resourceEditorDescriptions/GET_CLASS_DESCRIPTION_FAIL",
  REMOVE_EDITOR_DESCRIPTION: "triply/resourceEditorDescriptions/REMOVE_CLASS_DESCRIPTION",
} as const;

type GET_DESCRIPTION = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_EDITOR_DESCRIPTION,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_SUCCESS,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_FAIL,
    ];
    dataset: Dataset;
    resource: string;
  },
  Routes.datasets._account._dataset.sparql.Post
>;

type GET_CLASS_DESCRIPTION = GlobalAction<
  {
    types: [
      typeof LocalActions.GET_EDITOR_DESCRIPTION_CLASS,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_CLASS_SUCCESS,
      typeof LocalActions.GET_EDITOR_DESCRIPTION_CLASS_FAIL,
    ];
    dataset: Dataset;
    classIri: string;
  },
  Routes.datasets._account._dataset.sparql.Post
>;
type REMOVE_EDITOR_DESCRIPTION = GlobalAction<{
  type: typeof LocalActions.REMOVE_EDITOR_DESCRIPTION;
  dataset: Dataset;
  resource: string;
}>;

export type LocalAction = GET_DESCRIPTION | GET_CLASS_DESCRIPTION | REMOVE_EDITOR_DESCRIPTION;

export type Triple = Models.QueryResult;
export type Statements = Triple[];

export type NodeKind = "IRI" | "Literal" | "BlankNode";
export interface ResourceEditorDescriptions {
  nodeKind: NodeKind;
  value: string;
  type: string;
  typeLabel?: string;
  valueLabel?: string;
  properties?: {
    [propertyIri: string]: Property[];
  };
}

interface BaseProperty {
  nodeKind: NodeKind;
  value: string;
  key: string;
  predicate: string;
}
interface LiteralProperty extends BaseProperty {
  nodeKind: "Literal";
  datatype?: string;
  language?: string;
}
interface IRIProperty extends BaseProperty {
  nodeKind: "IRI";
  valueLabel?: string;
}
export interface BlankNodeProperty extends BaseProperty {
  nodeKind: "BlankNode";
  properties: { [propertyIri: string]: Property[] };
  type?: string;
}

export interface ResourceEditorClass {
  groupIri?: string;
  groupName: string;
  properties?: Array<{
    propertyShape: string;
    propertyShapeLabel: string;
    path: string;
  }>;
}

export type Property = LiteralProperty | IRIProperty | BlankNodeProperty;

export interface State {
  [datasetId: string]: {
    resources: { [propertyId: string]: ResourceEditorDescriptions };
    classShapes: { [classIri: string]: { groups: ResourceEditorClass[] } };
  };
}

export const reducer = produce(
  (draftState: State, action: Action) => {
    switch (action.type) {
      case Actions.GET_EDITOR_DESCRIPTION_SUCCESS:
        let instanceResult = converter.sparqlJsonResultsToTree(action.result, {
          singularizeVariables: {
            "": true,
            ...fromPairs(action.result.head!.vars.map((v: string) => [v, true])),
          },
        });
        if (!instanceResult.nodeKind) return;
        instanceResult.properties = transformProperties(instanceResult.properties);
        if (!draftState[action.dataset.id]) draftState[action.dataset.id] = { classShapes: {}, resources: {} };
        draftState[action.dataset.id].resources[action.resource] = instanceResult;
        return;
      case Actions.GET_EDITOR_DESCRIPTION_CLASS_SUCCESS:
        let classResult = converter.sparqlJsonResultsToTree(action.result, {
          singularizeVariables: {
            "": true,
            ...fromPairs(action.result.head!.vars.map((v: string) => [v, true])),
          },
        });
        if (!draftState[action.dataset.id]) draftState[action.dataset.id] = { classShapes: {}, resources: {} };
        draftState[action.dataset.id].classShapes[action.classIri] = classResult;
        return;
      case Actions.REMOVE_EDITOR_DESCRIPTION:
        delete draftState[action.dataset.id].resources[action.resource];
        return;
    }
  },
  <State>{},
);

interface GetDescriptionOptions {
  dataset: Dataset;
  resource: string;
}
interface GetClassOptions {
  dataset: Dataset;
  classIri: string;
}

export function getDescription(opts: GetDescriptionOptions): BeforeDispatch<GET_DESCRIPTION> {
  const { dataset, resource } = opts;
  return {
    types: [
      Actions.GET_EDITOR_DESCRIPTION,
      Actions.GET_EDITOR_DESCRIPTION_SUCCESS,
      Actions.GET_EDITOR_DESCRIPTION_FAIL,
    ],
    promise: (client) => {
      return client.req({
        pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/sparql`,
        body: { query: valuesQuery(resource) },

        method: "post",
        accept: "application/sparql-results+json",
      });
    },
    dataset: dataset,
    resource: resource,
  };
}
export function getClassDescription(opts: GetClassOptions): BeforeDispatch<GET_CLASS_DESCRIPTION> {
  const { dataset, classIri } = opts;
  return {
    types: [
      Actions.GET_EDITOR_DESCRIPTION_CLASS,
      Actions.GET_EDITOR_DESCRIPTION_CLASS_SUCCESS,
      Actions.GET_EDITOR_DESCRIPTION_CLASS_FAIL,
    ],
    promise: (client) => {
      return client.req({
        pathname: `/datasets/${dataset.owner.accountName}/${dataset.name}/sparql`,
        body: { query: classQuery(classIri) },

        method: "post",
        accept: "application/sparql-results+json",
      });
    },
    dataset: dataset,
    classIri: classIri,
  };
}
export function removeEditorDescription(dataset: Dataset, resource: string): BeforeDispatch<REMOVE_EDITOR_DESCRIPTION> {
  return {
    type: Actions.REMOVE_EDITOR_DESCRIPTION,
    dataset: dataset,
    resource: resource,
  };
}

type DescriptionFromStateOptions = GetDescriptionOptions & {
  state: State | undefined;
};
type ClassFromStateOptions = GetClassOptions & {
  state: State | undefined;
};

export function descriptionIsLoadedFor(opts: DescriptionFromStateOptions) {
  const { dataset, resource } = opts;
  if (!resource) return true;
  const resourceDescription = opts.state?.[dataset.id]?.resources?.[opts.resource];
  return !!resourceDescription;
}
export function classIsLoadedFore(opts: ClassFromStateOptions) {
  const { dataset, classIri } = opts;
  if (!classIri) return true;
  const resourceDescription = opts.state?.[dataset.id]?.classShapes?.[opts.classIri];
  return !!resourceDescription;
}

export function getDescriptionFor(opts: DescriptionFromStateOptions) {
  const { dataset, resource } = opts;
  if (descriptionIsLoadedFor(opts)) {
    return opts.state?.[dataset.id].resources[resource];
  }
}
export function getStateDescriptionFor(opts: ClassFromStateOptions) {
  const { dataset, classIri } = opts;
  if (classIsLoadedFore(opts)) {
    return opts.state?.[dataset.id].classShapes[classIri];
  }
}

const valuesQuery = (resource: string) => `
prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix skos: <http://www.w3.org/2004/02/skos/core#>
select
  *
where {
  bind(<${resource}> as ?value)
  ?value a ?type .
  optional {
    ?type rdfs:label ?typeLabel
  }
  optional {
    ?type skos:prefLabel ?typeLabel
  }
  optional {
    ?value rdfs:label ?valueLabel
  }
  optional {
    ?value skos:prefLabel ?valueLabel
  }
  bind("IRI" as ?nodeKind)
  optional {
    ?value ?properties_predicate ?properties_value.
    filter(?properties_predicate != rdf:type)
    bind(if(isiri(?properties_value), if(regex(str(?properties_value), "\.well-known/genid"), "BlankNode", "IRI"), "Literal") as ?properties_nodeKind)

    optional {
      filter(?properties_nodeKind = "IRI")
      optional {
        ?properties_value rdfs:label ?properties_valueLabel
      }
      optional {
        ?properties_value skos:prefLabel ?properties_valueLabel
      }

    }

    optional {
      filter(?properties_nodeKind = "Literal")
      bind(datatype(?properties_value) as ?properties_datatype)
      bind(lang(?properties_value) as ?properties_language)
    }
    optional {
      filter(?properties_nodeKind = "BlankNode")
      ?properties_value a ?properties_type .
      ?properties_value ?properties_properties_predicate ?properties_properties_value
      filter(?properties_properties_predicate != rdf:type)
      bind(if(isiri(?properties_properties_value), if(regex(str(?properties_properties_value), "\.well-known/genid"), "BlankNode", "IRI"), "Literal") as ?properties_properties_nodeKind)
      optional {
        filter(?properties_properties_nodeKind = "Literal")
        bind(datatype(?properties_properties_value) as ?properties_properties_datatype)
        bind(lang(?properties_properties_value) as ?properties_properties_language)
      }
    }
  }
}

`;

const NO_GROUP = "none";
const classQuery = (classIri: string) => `
    prefix sh: <http://www.w3.org/ns/shacl#>
    prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

    select
      ?groups_groupIri
      ?groups_groupName
      ?groups_properties_propertyShape
      ?groups_properties_propertyShapeLabel
      ?groups_properties_path
    where {
      <${classIri}> rdfs:subClassOf*/^sh:targetClass ?nodeShape .
      ?nodeShape sh:property ?groups_properties_propertyShape .
      ?groups_properties_propertyShape sh:path ?groups_properties_path .
      optional {
        ?groups_properties_propertyShape sh:name ?groups_properties_propertyShapeLabel.
      }
      optional {
        ?groups_properties_propertyShape sh:order ?groups_properties_order .
      }
      optional {
        ?groups_properties_propertyShape sh:group ?groupIri .
        ?groupIri rdfs:label ?groups_groupName ;
               sh:order ?groups_groupOrder .

      }
      bind(coalesce(?groupIri, "${NO_GROUP}") as ?groups_groupIri)
    }
    order by (!bound(?groups_groupOrder)) coalesce(?groups_groupOrder, -1000000) (!bound(?groups_properties_order)) ?groups_properties_order
`;

const transformProperties = (properties: any) => {
  if (!properties) return;
  return groupBy(
    properties.map((v: any) => ({
      ...v,
      value: `${v.value}`,
      properties: transformProperties(v.properties),
    })),
    "predicate",
  );
};
